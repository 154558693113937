


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TNewsIssue } from '@/_types/news-issue.type';
import FormFileUploader from '@/_modules/controls/components/form-file-uploader/form-file-uploader.vue';
import newsApi, {
  TCreateEditNewsIssueQueryParams,
  TCreateNewsIssueParams,
  TEditNewsIssueParams
} from '@/_api/news.api';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconPlus from '@/_modules/icons/components/icon-plus.vue';
import { TFile } from '@/_types/file.type';
import { TEvent } from '@/_types/event.type';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import IconCloseCircleOutline from '@/_modules/icons/components/icon-close-circle-outline.vue';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import ErrorInfo from '@/_modules/error-info/error-info.vue';

type TNewsIssueTranslations = {
  [lang2LetterCode: string]: TTranslatablePropertiesOfNewsIssue;
}

type TTranslatablePropertiesOfNewsIssue = {
  title: string;
  text: string;
}

@Component({
  components: {
    FormFileUploader,
    IconSquareEdit,
    IconSquareDelete,
    IconPlus,
    IconCloseCircleOutline,
    ErrorInfo
  }
})
export default class CabinetNewsEditForm extends Vue {

  @Getter('_eventStore/event') event: TEvent;

  public isUnsavedChangesConfirmVisible: boolean = false;
  public editingNewsIssueClone: TNewsIssue = null;
  public newsForm: TCreateEditNewsIssueQueryParams = {
    title: '',
    sorting: null,
    text: '',
    photos: [],
    is_published: false,
  }

  public newsIssueTranslations: TNewsIssueTranslations = {};
  public newsIssueTranslationsLastSaved: TNewsIssueTranslations = {}; // Used for comparison: is current translation data saved?
  public translatablePropertiesOfNewsIssue: TTranslatablePropertiesOfNewsIssue = {
    title: '',
    text: ''
  }
  public isTranslationInitializationComplete: boolean = false;

  public isNewsImageLoading: boolean = false;
  public newsImageError: string = '';
  public newsImageFile: TFile = {
    url: '',
    filename: '',
  };

  public newsIssueError: ApiErrorResponseData = null;

  public selectedLanguageTab: string = 'en';

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isEditMode(): boolean {
    return !!(this.editingNewsIssueClone && this.editingNewsIssueClone.id);
  }

  public get eventLanguages(): string[] {
    if (this.event && this.event.languages && this.event.languages.length) {
      return this.event.languages;
    }
    return ['en'];
  }

  public get currentEditLanguage(): string {
    if (this.eventLanguages && this.eventLanguages.length > 0) {
      if (this.eventLanguages.indexOf(this.selectedLanguageTab) > -1) {
        return this.selectedLanguageTab;
      }
      return this.eventLanguages[0];
    }

    return 'en';
  }

  public get isNewsEditFormAvailable(): boolean {
    return !!(
      this.newsIssueTranslations
      && Object.keys(this.newsIssueTranslations)
      && this.eventLanguages
      && this.eventLanguages.length
      && this.isTranslationInitializationComplete
    );
  }

  public get areAllTranslationsSaved(): boolean {
    let result = true;

    for (let i = 0; i < this.eventLanguages.length; i++) {
      const checkedLangCode = this.eventLanguages[i];
      if (!_isEqual(this.newsIssueTranslations[checkedLangCode], this.newsIssueTranslationsLastSaved[checkedLangCode])) {
        result = false;
        break;
      }
    }

    return result;
  }

  public get isTranslationSaved(): {[langCode: string]: boolean} {
    const result: {[langCode: string]: boolean} = {};

    this.eventLanguages.forEach(langCode => {
      result[langCode] = _isEqual(this.newsIssueTranslations[langCode], this.newsIssueTranslationsLastSaved[langCode]);
    });

    return result;
  }

  public get isSubmitDisabled(): boolean {
    const lang: string = this.currentEditLanguage;
    return !this.newsIssueTranslations[lang].title || !this.newsIssueTranslations[lang].text;
  }

  public get isLangTabDisabledByLangCode(): {[langCode: string]: boolean} {
    const result: {[langCode: string]: boolean} = {};

    this.eventLanguages.forEach(langCode => {
      result[langCode] = this.isEditMode ? false : (langCode !== 'en');
    });

    return result;
  }

  public get isEditPhotoUrlAllowed(): boolean {
    return this.currentEditLanguage === 'en';
  }

  @Prop({ type: Object, default: null })
  public readonly editingNewsIssue: TNewsIssue;

  @Watch('editingNewsIssue', { immediate: true })
  private onEditingNewsIssueChange(): void {
    this.editingNewsIssueClone = _cloneDeep(this.editingNewsIssue);
  }

  public mounted(): void {
    this.initTranslatableFields();
    this.getExistingTranslations();
    this.initEditingEntity();
  }

  private onCloseClick(): void {
    if (!this.areAllTranslationsSaved) {
      this.showUnsavedChangesConfirm();
      return;
    }

    this.$emit('close');
  }

  private showUnsavedChangesConfirm(): void {
    this.isUnsavedChangesConfirmVisible = true;
  }

  private hideUnsavedChangesConfirm(): void {
    this.isUnsavedChangesConfirmVisible = false;
  }

  private onCloseAnywayClick(): void {
    this.hideUnsavedChangesConfirm();
    this.$emit('close');
  }

  private onReturnToEditingClick(): void {
    this.hideUnsavedChangesConfirm();
  }

  /* Creates the following structure to be filled with news translations:
      en: { title: '', text: ''},
      ...
   */
  private initTranslatableFields(): void {
    this.eventLanguages.forEach(langCode => {
      // Using $set because of reactivity, see https://vuejs.org/v2/guide/reactivity.html#For-Objects
      this.$set(this.newsIssueTranslations, langCode, {
        ...this.translatablePropertiesOfNewsIssue
      });
    });
  }

  private updateLastSavedTranslations(lang?: string): void {
    if (lang && this.newsIssueTranslationsLastSaved[lang]) {
      this.newsIssueTranslationsLastSaved[lang] = {
        ...this.newsIssueTranslations[lang]
      };
      this.newsIssueTranslationsLastSaved = Object.assign({}, this.newsIssueTranslationsLastSaved);
      return;
    }
    this.newsIssueTranslationsLastSaved = _cloneDeep(this.newsIssueTranslations);
  }

  private async getExistingTranslations(): Promise<void> {
    if (!this.isEditMode) {
      this.updateLastSavedTranslations();
      this.isTranslationInitializationComplete = true;
      return;
    }

    for (const langCode of this.eventLanguages) {
      const existingNewsTranslation: TNewsIssue = await newsApi.getNewsIssue({
        eventId: this.eventId,
        newsId: this.editingNewsIssueClone.id,
        acceptLanguage: langCode
      });

      this.newsIssueTranslations[langCode].title = existingNewsTranslation.title;
      this.newsIssueTranslations[langCode].text = existingNewsTranslation.text;
    }

    this.updateLastSavedTranslations();
    this.isTranslationInitializationComplete = true;
  }

  private initEditingEntity(): void {
    if (this.editingNewsIssue && this.editingNewsIssue.id) {
      const newObj = {...this.editingNewsIssue};
      delete newObj.id;
      delete newObj.created_at;
      this.newsForm = {...newObj};
      if (this.newsForm.photos && this.newsForm.photos.length) {
        this.newsImageFile.url = this.newsForm.photos[0];
      }
    }
  }

  private onSubmit(): void {

    // TODO: show error on required fields (title, text)
    if (this.isSubmitDisabled) {
      return;
    }

    if (this.isEditMode) {
      this.editGivenIssue();
    } else {
      this.createNewIssue();
    }

  }

  private async createNewIssue(): Promise<void> {

    this.newsForm.title = this.newsIssueTranslations.en.title;
    this.newsForm.text = this.newsIssueTranslations.en.text;

    const payload: TCreateNewsIssueParams = {
      eventId: this.eventId,
      data: {
        ...this.newsForm
      }
    };

    let createdNewsIssue: TNewsIssue;

    try {
      createdNewsIssue = await newsApi.createNewsIssue(payload);
    } catch (error) {
      this.newsIssueError = error.data;
      return;
    }

    if (createdNewsIssue && createdNewsIssue.id) {
      this.editingNewsIssueClone = {
        ...createdNewsIssue
      };
      this.updateLastSavedTranslations(this.currentEditLanguage);
    }

    this.$emit('success');

  }

  private async editGivenIssue(): Promise<void> {

    this.newsForm.title = this.newsIssueTranslations[this.currentEditLanguage].title;
    this.newsForm.text = this.newsIssueTranslations[this.currentEditLanguage].text;

    const payload: TEditNewsIssueParams = {
      eventId: this.eventId,
      newsId: this.editingNewsIssueClone.id,
      data: {
        ...this.newsForm,
        lang: this.currentEditLanguage
      },
    };

    try {
      await newsApi.editNewsIssue(payload);
    } catch (error) {
      this.newsIssueError = error.data;
      return;
    }

    // TODO: error handling

    this.updateLastSavedTranslations(this.currentEditLanguage);

    if (this.areAllTranslationsSaved) {
      this.$emit('success');
    }

  }

  private setIsNewsImageLoading(value: TFile): void {
    if (value && value.url === '') {
      this.isNewsImageLoading = true;
      return;
    } else if (value.url) {
      this.isNewsImageLoading = false;
      this.newsForm.photos = [value.url];
    }
    this.isNewsImageLoading = false;
  }

  private setNewsImageError(err: string): void {
    this.newsImageError = err || '';
    setTimeout(() => {
      this.newsImageError = '';
      this.isNewsImageLoading = false;
    }, 3000);
  }

  private deleteNewsImage(): void {
    this.newsForm.photos = [];
    this.newsImageFile.url = '';
  }

  private onNewsImageInput(value: TFile): void {
    this.newsForm.photos = [value.url];
    this.isNewsImageLoading = false;
  }

  private onLanguageTabClick(langCode: string): void {

    if (this.isLangTabDisabledByLangCode[langCode]) {
      return;
    }

    this.selectedLanguageTab = langCode;
  }

  private onNewsIssueTranslationsKeyup(): void {
    const newVal: TNewsIssueTranslations = this.newsIssueTranslations;

    if (newVal && this.currentEditLanguage && newVal[this.currentEditLanguage]) {
      this.newsForm.title = newVal[this.currentEditLanguage].title;
      this.newsForm.text = newVal[this.currentEditLanguage].text;
    }
  }

  public get isFillFromEnglishButtonVisible(): boolean {
    return this.eventLanguages && this.eventLanguages.length > 1 && this.currentEditLanguage !== 'en';
  }

  private fillFromEnglish(): void {
    if (!this.newsIssueTranslations || !Object.prototype.hasOwnProperty.call(this.newsIssueTranslations, 'en')) {
      return;
    }

    this.newsIssueTranslations[this.currentEditLanguage] = _cloneDeep(this.newsIssueTranslations.en);
  }

}
